<template>
  <div class="another-number">
    <div class="left">
      <div class="images">
        <skeleton-box
            v-if="loading"
            :width="this.$store.getters.width > 768 ? '240px' : '100%'"
            height="240px"/>
        <slot
            v-else
            name="images"
        />
      </div>
    </div>
    <div class="right">
      <div class="top">
        <div class="top-left">
          <div class="name">
            <skeleton-box
                v-if="loading"
                width="240px"
                height="25px"/>
            <slot
                v-else
                name="name"
            />
          </div>
          <a class="detailed">
            <skeleton-box
                v-if="loading"
                width="150px"
                height="20px"/>
            <slot
                v-else
                name="detailed"
            />
          </a>
        </div>
      </div>
      <div class="rooms-type">
        <div class="room">
          <div class="additions">
            <skeleton-box
                v-if="loading"
                width="150px"
                height="20px"/>
            <slot
                v-else
                name="good"
            />

            <skeleton-box
                v-if="loading"
                width="150px"
                height="20px"
                margin="5px 0 0 0"/>
            <slot
                v-else
                name="bad"
            />

          </div>
          <div class="price-and-book">
            <div class="price-info">
              <p class="price">
                <skeleton-box
                    v-if="loading"
                    width="100px"
                    height="20px"/>
                <slot
                    v-else
                    name="price"
                />
              </p>
              <p class="days">
                <skeleton-box
                    v-if="loading"
                    width="90px"
                    height="17px"/>
                <slot
                    v-else
                    name="days"
                />
              </p>
            </div>
            <div class="book-btn">
              <a>
                <skeleton-box
                    v-if="loading"
                    width="117px"
                    height="37px"/>
                <slot
                    v-else
                    name="button"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SkeletonBox from "@/components/loaders/SkeletonBox";

export default {
  name: `number-item`,
  components: {SkeletonBox},
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
  },
  methods: {}
};
</script>
