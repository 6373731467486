import {log} from "util";

export default class Metapolicy {
    titles = {
        "cot": "Детская люлька",
        "meal": "Питание",
        "pets": "Животные",
        "add_fee": "Дополнительные услуги",
        "deposit": "Депозит",
        "parking": "Парковка",
        "shuttle": "Трансфер",
        "children": "Дети",
        "extra_bed": "Дополнительная кровать",
        "children_meal": "Питание для детей",
        "unspecified": "не указано",
        "television": "Телевизор",
        "towels": "Полотенца",
        "conditioning": "Кондиционер",
        "housekeeping": "уборка номеров",
        "heating": "отопление",
        "refrigerator": "Холодильник",
        "utility": "коммунальные услуги",
        "safe": "сейф",
        "microwave": "Микроволновая печь",
        "luggage_storage": "хранение багажа",
        "tour_guide": "путеводитель",
        "bicycle_rental": "прокат велосипедов",
        "baby_highchair": "детское кресло",
        "bed_linen": "Постельное белье",
        "towels_only": "Только полотенца",
        "luggage_storage_apartment": "хранение багажа в квартире",
        "luggage_storage_office": "хранение багажа в офисе",
        "breakfast": "Завтрак",
        "keys": "ключи",
        "pet": "животных",
        "breakage": "возможную порчу имущества",
        "card": "картой",
        "hotel": "лобби",
        "internet": "Интернет",
        "visa": "Visa",
        "per_guest_per_night": "с гостя на ночь",
        "per_guest_per_stay": "с гостя за весь период проживания",
        "per_room_per_night": "с номера за ночь",
        "per_room_per_stay": "на номер за весь период проживания",
        "per_hour": "на час",
        "per_week": "на неделю",
        "airport": "Аэропорт",
        "train": "Вокзал",
        "ship": "Паром",
        "airport_train": "Аэроэкспресс"
    }

    index = null
    value = null

    constructor(index = null, value = null) {
        this.index = index
        this.value = value
    }

    getInstance() {
        switch (this.index) {
            case 'add_fee': {
                return this.addFee()
            }
            case 'check_in_check_out': {
                return ''
            }
            case 'children': {
                return this.children()
            }
            case 'children_meal': {
                return this.childrenMeal()
            }
            case 'cot': {
                return this.cot()
            }
            case 'deposit': {
                return this.deposit()
            }
            case 'extra_bed': {
                return this.extraBed()
            }
            case 'internet': {
                return this.internet()
            }
            case 'meal': {
                return this.meal()
            }
            case 'no_show': {
                return this.noShow()
            }
            case 'parking': {
                return this.parking()
            }
            case 'pets': {
                return this.pets()
            }
            case 'shuttle': {
                return this.shuttle()
            }
            case 'visa': {
                return this.visa()
            }
        }
    }

    getTitles() {
        return this.titles[this.index]
    }

    addFee() {
        return this.titles[this.value.fee_type] + ': ' + this.value.price + ' ' + this.value.currency + ' ' + this.titles[this.value.price_unit]
    }

    checkInCheckOut() {

    }

    children() {
        let price = parseFloat(this.value.price)

        if (price === 0 && this.value.currency !== null) {
            price = 'Бесплатно'
        } else if (price === 0 && this.value.currency === null) {
            price = 'цену уточнять на месте'
        } else {
            price = price + ' ' + this.value.currency
        }

        if (this.value.age_start === 0) {
            return 'В возрасте до ' + this.value.age_end + ' лет при условии их размещения в одной комнате с родителями или опекунами на дополнительных кроватях: ' + price
        } else if (this.value.age_start !== 0 && this.value.age_end !== 0) {
            return 'В возрасте от ' + this.value.age_start + ' до ' + this.value.age_end + ' лет при условии их размещения в одной комнате с родителями или опекунами на дополнительных кроватях: ' + price
        }
    }

    childrenMeal() {
        if (this.value.age_start === 0) {
            return 'Завтрак: ' + this.value.price + ' ' + this.value.currency + ' c гостя (ребёнок до ' + this.value.age_end + ')'
        } else if (this.value.age_start !== 0 && this.value.age_end !== 0) {
            return 'Завтрак: ' + this.value.price + ' ' + this.value.currency + ' c гостя (ребёнок с ' + this.value.age_start + ' до ' + this.value.age_end + ')'
        }
    }

    cot() {
        let price
        if (this.value.inclusion === 'included') {
            price = parseFloat(this.value.price) === 0 ? 'Бесплатно' : this.value.price + ' ' + this.value.currency
        } else {
            price = 'цену уточнять на месте'
        }

        return 'Количество уточнять на месте: ' + price
    }

    deposit() {
        if (this.value['deposit_type'] !== 'unspecified') {
            return 'За ' + this.titles[this.value['deposit_type']] + ' ' + this.value.price + ' ' + this.value.currency + ' ' + this.titles[this.value['price_unit']]
        } else {
            return this.value.price + ' ' + this.value.currency + ' ' + this.titles[this.value['price_unit']]
        }

    }

    extraBed() {
        let price = parseFloat(this.value.price) === 0 ? 'Бесплатно' : this.value.price + ' ' + this.value.currency
        return 'Количество уточнять на месте: ' + price
    }

    internet() {
        let price = parseFloat(this.value.price) === 0 ? 'Бесплатно' : this.value.price + ' ' + this.value.currency
        return 'В ' + this.titles[this.value['work_area']] + ': ' + price
    }

    meal() {
        let price = parseFloat(this.value.price) === 0 ? 'Бесплатно' : this.value.price + ' ' + this.value.currency
        return this.titles[this.value['meal_type']] + ': ' + price + ' с гостя'
    }

    noShow() {

    }

    parking() {
        let price = parseFloat(this.value.price) === 0 ? 'Бесплатно' : this.value.price + ' ' + this.value.currency
        return 'Уточняйте на месте: ' + price + ' за каждый автомобиль за ночь'
    }

    pets() {
        let price = parseFloat(this.value.price) === 0 ? 'Бесплатно' : this.value.price + ' ' + this.value.currency
        return 'Разрешены любые животные: ' + price + ' за каждое животное ' + this.titles[this.value['price_unit']].replace('с гостя', '')
    }

    shuttle() {
        let price = parseFloat(this.value.price)

        if (price === 0 && this.value.currency !== null) {
            price = 'Бесплатно'
        } else if (price === 0 && this.value.currency === null) {
            price = 'цену уточнять на месте'
        } else {
            price = price + ' ' + this.value.currency
        }

        if (this.value.destination_type !== 'unspecified') {
            return this.titles[this.value['destination_type']] + ': ' + price
        }
    }

    visa() {
        if (this.value === 'support_enable') {
            return 'Оплата картой Visa возможна'
        } else {
            return 'Оплата картой Visa невозможна'
        }
    }

}
