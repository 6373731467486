<template>
  <div class="breadcrumbs">
    <ul v-if="crumbs.length > 0" class="crumbs">
      <li v-for="(item, index) in crumbs" class="crumbs-item" :class="item.class" :key="index">
        <span class="crumbs-link" v-if="!item.name">{{ item.label }}</span>
        <router-link
            :to="{ name: item.name, query: item.query }"
            class="crumbs-link active" v-else>
          {{ item.label }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbs',
  props: ['breadcrumbs'],
  data() {
    return {
      crumbs: []
    }
  },
  mounted() {
    this.buildCrumbs()
  },
  methods: {
    buildCrumbs() {
      this.crumbs = this.breadcrumbs;
    },
  }
}
</script>

<style scoped>
.breadcrumbs {
  padding: 25px 10px;
  background: #FFFFFF;
  position: relative;
}

@media screen and (max-width: 992px) {
  .breadcrumbs {
    padding: 0 0 0 10px;
  }
}

.breadcrumbs .crumbs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
}

.breadcrumbs .crumbs .crumbs-item {
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}

@media screen and (max-width: 992px) {
  .breadcrumbs {
    margin-top: 10px;
  }
  .breadcrumbs .crumbs .crumbs-item .crumbs-link {
    font-size: 14px;
  }
}

.breadcrumbs .crumbs .crumbs-item .crumbs-link.active {
  color: #005BFF;
}

.breadcrumbs .crumbs .crumbs-item:not(:last-child):after {
  content: '>';
  margin: 0 5px 0 5px;
}

</style>
